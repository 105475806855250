import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepIcon from "../assets/icons/login_step_icon.svg";
import "./Login/styles.css";
import { authActions } from "../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import landingPageBG from "../assets/images/landingPageBG.png";
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';
import ContactUsModal from "../common/ContactUsModal";



const LandingPage = () => {

    const getCurrentYear = () => {
        return new Date().getFullYear();
      };

      

      const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.sendForm('service_jgrrm2n', 'template_bmh9rdf', event.target, 'YOUR_USER_ID')
      .then((result) => {
        console.log(result.text);
        alert('Message sent!');
        handleCloseModal();
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message.');
      });
  };

return (

    <div className="">
    <header className="relative w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${landingPageBG})`   }}>
      <nav className="absolute top-0 right-0 flex p-4 z-30 space-x-4">
        
{/*         
        <Link  className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline" to={"/"}>
        Pricing
        </Link>

        
        <Link className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline" to={"/"}>
        Event Manager Access
        </Link> */}

        <Link className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline " to={"/login"}>
        Vendor Login
        </Link>
        
        
      </nav>
      <div className="absolute inset-0 flex flex-col items-start mx-24 justify-center text-left max-sm:mx-5">
        <h1 className="text-white text-6xl font-bold w-1/2 max-sm:w-full max-sm:text-5xl ">Manage event Queues and reach nearby customers </h1>
        
        <Link className="" to={"/register"}>
                        <button className="mt-10 px-20 py-3 bg-[#DE0000] text-white rounded font-bold hover:bg-red-700">Sign up</button>
        </Link>
       
      </div>
    </header>
    
    
    <div className="flex flex-col gap-5 w-full justify-center items-center text-center">
            <section className="my-8 ">
                <h2 className="text-2xl font-bold mb-4">
                    How it works
                </h2>
                <div className="flex flex-row  gap-5 text-left p-6 mx-10 max-sm:flex-wrap">
                    <div>
                    <p className="text-lg font-bold">Event Analytics & Insights</p>
                    <p>
                    Access real-time sales data and post-event reports to monitor vendor performance, attendee engagement, and purchasing trends, helping you optimize future events for success.
                </p> </div>
                <div>
                    <p className="text-lg font-bold">Improve Attendee experience</p>
                    <p>
                    Attendees can pre-order food, merchandise, and services through the No-Q web app, paying securely in advance. This eliminates long queues, allowing attendees to spend more time enjoying the event experience instead of waiting in lines.
                </p> </div>
                <div>
                    <p className="text-lg font-bold">Real-Time Order Fulfillment </p>
                    <p>
                    Event Vendors receive live order updates and notify attendees when their items are ready for pickup, ensuring fast and smooth transactions without delays.
                </p> </div>
                <div>
                    <p className="text-lg font-bold">Inventory Management</p>
                    <p>
                    Vendors can easily upload menus, manage inventory, and update order statuses in real-time, ensuring smooth operations and minimizing errors.
                </p> </div>
                </div>
                <Link className="" to={"/register"}>
                        <button className="mt-10 p-8 py-3 border-2 border-black text-black rounded font-bold hover:bg-black hover:text-white">Sign up</button>
        </Link>
                
            </section>
        <div className="w-full bg-[#CCD6E4] p-0  bottom-0">


                <section className="my-16">
                            {/* <a href className="p-5 underline cursor-pointer" onClick={handleOpenModal}> Contact Us </a> */}
                            <a href="mailto:contact@no-Q.ca?subject=NO-Q Inquiry" className="underline p-5 my-5">Contact Us</a>

                    <p className="my-5"> &copy; {getCurrentYear()} NO-Q. All rights reserved.  </p>
                    
                </section>
      </div>
    </div>
    <ContactUsModal show={showModal} handleClose={handleCloseModal} handleSubmit={handleSubmit} />
  </div>


);









};
export default LandingPage;