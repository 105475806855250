import React, { useEffect, useState } from "react";
import "./Login/styles.css";
import { useLocation } from "react-router-dom";
import landingPageBG from "../assets/images/landingPageBG.png";
import { Link } from "react-router-dom";
import NoQ_logo from "../assets/icons/NO-Q_logo.png";
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import Image from 'next/image';
import heroImage from "../assets/heroImage.png";
import howitworks1 from "../assets/howitworks1.png";
import howitworks2 from "../assets/howitworks2.png";

const LandingPage = () => {
    const location = useLocation();

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    const [formData, setFormData] = useState({
        FNAME: "",
        LNAME: "",
        COMPANY: "",
        EMAIL: "",
        PHONE: "",
        MESSAGE: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (subscribe) => (e) => {
        e.preventDefault();
        subscribe(formData);
    };

    useEffect(() => {
        // Check if there's a hash in the URL
        const { hash } = location;

        if (hash) {
            const idValue = hash?.slice(1);
            const element = document.getElementById(idValue);

            if (element) {
                // Smooth scroll to the element
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [location]);

    return (
        <div className="font-sans mt-32 max-sm:px-0 max-sm:mt-20">

            <nav className="absolute top-0 right-0 flex justify-between p-4 z-30 space-x-4 w-full bg-[#181818] h-20 items-center  max-sm:h-14">
                <div className="left-0 flex w-36 ml-10 max-sm:ml-3">
                    <img src={NoQ_logo} className="w-16 h-16" />
                </div>

                <div>

                    {/* <Link  className="text-white p-2  hover:underline max-sm:underline" to={"/"}>
        Pricing
        </Link>

        
        <Link className="text-white p-2 hover:underline max-sm:underline" to={"/"}>
        Event Manager Access
        </Link>
        
        <Link className="text-white p-2 hover:underline max-sm:active:underline" to={"/"}>
        F.A.Q.
        </Link> */}


                    {/* <Link
                        className="text-white p-2 text-md mr-5 hover:underline hover:font-bold max-sm:active:underline "
                        to={"/login"}
                    >
                        Vendor Login
                    </Link> */}
                </div>


            </nav>




            <div className=" inset-0 flex flex-row my-8 px-16 justify-center items-center text-left max-sm:mx-1 max-sm:flex-col max-sm:text-center max-sm:my-0 max-sm:px-0">
                <div className="flex flex-col w-[40%] max-sm:w-full max-sm:justify-center max-sm:items-center ">
                    <h1 className="text-black text-7xl font-bold w-full mb-4 max-lg:w-full max-sm:text-5xl ">
                        Boost event<br /> sales and
                        <p className="text-red-600">eliminate <br /> queues{" "}</p>
                    </h1>

                    <p className="text-md w-[80%] mb-4 ">
                        No-Q platform allows outdoor event vendors to list their products or services on an online event marketplace, making it easy for attendees to order from their phones and for vendors to serve more customers and eliminate long queues.
                    </p>

                    {/* <Link className="" to={"/register"}> */}
                    <Link className="w-fit" to='#waitlist'>

                        <button className="px-20 py-3 bg-[#DE0000] text-white rounded font-bold hover:bg-red-700 max-sm:active:bg-red-700">
                            Join vendor waitlist
                        </button>
                    </Link>
                </div>
                <div className="w-[60%] max-sm:w-full max-sm:mt-3">
                    <img src={heroImage} className="w-full" alt="Hero Image" />
                </div>



            </div>

            <div className="flex flex-col gap-5 w-full justify-center items-center text-center  ">
                <section className="my-8  max-sm:px-5 max-sm:my-0  " id="howitworks">
                    <div className="flex w-full px-16 items-start max-sm:px-0">
                        <h2 className="text-xl font-bold mb-4 bg-[#C0DAFF] rounded-full px-6 py-2 ">How it works</h2>
                    </div>

                    <div className="flex flex-col">



                        <div className="flex flex-row justify-center items-center w-fit max-sm:flex-col max-sm:gap-5 max-sm:mb-10 ">

                            <img src={howitworks1} alt="How it Works1" className="w-[50%] max-sm:w-[80%] " />

                            <div className=" flex-col text-start items-center ml-10  max-sm:ml-0 max-sm:px-3 max-sm:mb-10 ">
                                <p className="text-xl font-bold max-sm:px-0 max-sm:mt-[-30px]">Boost Event Sales</p><br />
                                <p className="w-[70%] text-lg max-sm:w-full">
                                    Our platform helps you reach nearby customers instantly, enabling them to browse your menu, place orders, and pay online—all before they even reach your stall to pick up. By eliminating long queues, you can serve more customers and reduce wait times.
                                </p></div>

                        </div>


                        <div className="flex flex-row justify-center items-center w-fit max-sm:flex-col max-sm:gap-5 max-sm:mb-10 ">

                            <img src={howitworks2} alt="How it Works2" className="w-[50%] hidden max-sm:flex max-sm:w-full" />

                            <div className="w-[40%] flex-col text-start items-center ml-10 max-sm:ml-0 max-sm:px-3 max-sm:w-full">
                                <p className="text-xl font-bold max-sm:px-0 max-sm:mt-[-20px] ">Eliminate Queues</p><br />
                                <p className="w-[70%] text-lg max-sm:w-full">
                                    Attendees can pre-order food, merchandise, and services through the
                                    No-Q web app, paying securely in advance. This eliminates long
                                    queues, allowing attendees to spend more time enjoying the event
                                    experience instead of waiting in lines.
                                </p></div>
                            <img src={howitworks2} alt="How it Works2" className="w-[50%] max-sm:hidden" />

                        </div>




                    </div>
                    {/* <Link className="" to={"/register"}> */}
                    <Link className="" to='#waitlist'>
                        <button className="mt-10 p-8 py-3 border-2 border-black text-black rounded font-bold hover:bg-black hover:text-white active:bg-black active:text-white">
                            Join vendor waitlist
                        </button>
                    </Link>
                </section>


                <div className="w-full bg-black p-10 -mx-16 justify-center">
                    <div
                        id="waitlist"
                        className="flex flex-row w-full bg-white justify-center items-center max-sm:flex-col"
                    >
                        <div className="w-1/2 max-md:w-full flex justify-center items-center">
                            <img
                                src={NoQ_logo}
                                alt="logo"
                                width={"150px"}
                                height={"150px"}
                                className=""
                            />
                        </div>

                        <div className="w-1/2 max-md:w-full p-6">
                            <MailchimpSubscribe
                                url={
                                    "https://no-q.us3.list-manage.com/subscribe/post?u=cd71d5e41a5b14dea8e7ec891&amp;id=a9440f29f2&amp;f_id=0047c9e1f0"
                                }
                                render={({ subscribe, status, message }) => {
                                    return status === "success" ? (
                                        <div className="text-center py-[100px] max-d:py-[60px]">
                                            <i className="ri-checkbox-circle-fill text-[60px] text-green-600"></i>
                                            <p className="text-2xl font-semibold">
                                                Thank you. <br /> Form submitted successfully!
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-center">
                                            <h4 className="font-semibold text-xl mb-3">
                                                Join Our Waitlist
                                            </h4>
                                            <form
                                                className="flex flex-col space-y-2.5 w-[75%] max-lg:w-full"
                                                onSubmit={handleSubmit(subscribe)}
                                            >
                                                <input
                                                    type="text"
                                                    name="FNAME"
                                                    value={formData.FNAME}
                                                    onChange={handleChange}
                                                    placeholder="First Name"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    name="LNAME"
                                                    value={formData.LNAME}
                                                    onChange={handleChange}
                                                    placeholder="Last Name"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    name="COMPANY"
                                                    value={formData.COMPANY}
                                                    onChange={handleChange}
                                                    placeholder="Business Name"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="email"
                                                    name="EMAIL"
                                                    value={formData.EMAIL}
                                                    onChange={handleChange}
                                                    placeholder="Email Address"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="tel"
                                                    name="PHONE"
                                                    value={formData.PHONE}
                                                    onChange={handleChange}
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    placeholder="Phone Number (Optional)"
                                                />
                                                <textarea
                                                    name="MESSAGE"
                                                    value={formData.MESSAGE}
                                                    onChange={handleChange}
                                                    // className="textarea-input"
                                                    className="resize-none w-full py-1.5 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    rows={3}
                                                    placeholder="Message (Optional)"
                                                />
                                                <button
                                                    type="submit"
                                                    className="bg-black py-3 rounded-md text-white"
                                                >
                                                    {status === "sending"
                                                        ? "Joining..."
                                                        : "Join now"}
                                                </button>
                                            </form>

                                            {status === "error" && (
                                                <div style={{ color: "red" }}>{message}</div>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <p className="my-5 font-semibold text-white">
                        {" "}
                        &copy; {getCurrentYear()} NO-Q. All rights reserved.{" "}
                    </p>
                </div>
            </div>
        </div>
    );
};
export default LandingPage;
