import React, { useEffect, useState } from "react";
import "./Login/styles.css";
import { useLocation } from "react-router-dom";
import landingPageBG from "../assets/images/landingPageBG.png";
import { Link } from "react-router-dom";
import NoQ_logo from "../assets/icons/NO-Q_logo.png";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const LandingPage = () => {
    const location = useLocation();

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    const [formData, setFormData] = useState({
        FNAME: "",
        LNAME: "",
        COMPANY: "",
        EMAIL: "",
        PHONE: "",
        MESSAGE: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (subscribe) => (e) => {
        e.preventDefault();
        subscribe(formData);
    };

    useEffect(() => {
        // Check if there's a hash in the URL
        const { hash } = location;

        if (hash) {
            const idValue = hash?.slice(1);
            const element = document.getElementById(idValue);

            if (element) {
                // Smooth scroll to the element
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [location]);

    return (
        <div className="">
            <nav
                className="relative w-full h-screen bg-cover bg-center"
                style={{ backgroundImage: `url(${landingPageBG})` }}
            >
                <nav className="absolute top-0 right-0 flex p-4 z-30 space-x-4">
                    {/*         
        <Link  className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline" to={"/"}>
        Pricing
        </Link>

        
        <Link className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline" to={"/"}>
        Event Manager Access
        </Link> */}

                    {/* <Link
                        className="text-white p-2 hover:border-2 hover:font-bold max-sm:underline "
                        to={"/login"}
                    >
                        Vendor Login
                    </Link> */}
                </nav>
                <div className="absolute inset-0 flex flex-col items-start mx-24 justify-center text-left max-sm:mx-5">
                    <h1 className="text-white text-6xl font-bold w-1/2 max-lg:w-full max-sm:text-5xl ">
                        Boost your event sales and eliminate queues
                    </h1>

                    <Link className="" to={"#waitlist"}>
                        <button className="mt-10 px-20 py-3 bg-[#DE0000] text-white rounded font-bold hover:bg-red-700">
                            Join waitlist
                        </button>
                    </Link>
                </div>
            </nav>

            <div className="flex flex-col gap-5 w-full justify-center items-center text-center">
                <section className="my-8 ">
                    <h2 className="text-2xl font-bold mb-4">How it works</h2>
                    <div className="grid grid-cols-4 gap-5 text-left p-6 mx-10 max-lg:grid-cols-2 max-md:grid-cols-1">
                        {/* <div>
                            <p className="text-lg font-bold">Event Analytics & Insights</p>
                            <p>
                                Access real-time sales data and post-event reports to monitor vendor
                                performance, attendee engagement, and purchasing trends, helping you
                                optimize future events for success.
                            </p>{" "}
                        </div> */}
                        <div>
                            <p className="text-lg font-bold">Boost Event Sales</p>
                            <p>
                                Our platform helps you reach nearby customers instantly, enabling
                                them to browse your menu, place orders, and pay online—all before
                                they even reach your stall to pick up. By eliminating long queues,
                                you can serve more customers and reduce wait times.
                            </p>{" "}
                        </div>
                        <div>
                            <p className="text-lg font-bold">Improve Attendee experience</p>
                            <p>
                                Attendees can pre-order food, merchandise, and services through the
                                No-Q web app, paying securely in advance. This eliminates long
                                queues, allowing attendees to spend more time enjoying the event
                                experience instead of waiting in lines.
                            </p>{" "}
                        </div>
                        <div>
                            <p className="text-lg font-bold">Real-Time Order Fulfillment </p>
                            <p>
                                Event Vendors receive live order updates and notify attendees when
                                their items are ready for pickup, ensuring fast and smooth
                                transactions without delays.
                            </p>{" "}
                        </div>
                        <div>
                            <p className="text-lg font-bold">Inventory Management</p>
                            <p>
                                Vendors can easily upload menus, manage inventory, and update order
                                statuses in real-time, ensuring smooth operations and minimizing
                                errors.
                            </p>{" "}
                        </div>
                    </div>
                    <Link className="" to={"#waitlist"}>
                        <button className="mt-10 p-8 py-3 border-2 border-black text-black rounded font-bold hover:bg-black hover:text-white">
                            Join waitlist
                        </button>
                    </Link>
                </section>

                <div className="w-full bg-black p-10 justify-center">
                    <div
                        id="waitlist"
                        className="flex flex-row w-full bg-white justify-center items-center max-sm:flex-col"
                    >
                        <div className="w-1/2 max-md:w-full flex justify-center items-center">
                            <img
                                src={NoQ_logo}
                                alt="logo"
                                width={"250px"}
                                height={"250px"}
                                className="mr-5"
                            />
                        </div>

                        <div className="w-1/2 max-md:w-full p-6">
                            <MailchimpSubscribe
                                url={
                                    "https://no-q.us3.list-manage.com/subscribe/post?u=cd71d5e41a5b14dea8e7ec891&amp;id=a9440f29f2&amp;f_id=0047c9e1f0"
                                }
                                render={({ subscribe, status, message }) => {
                                    return status === "success" ? (
                                        <div className="text-center py-[100px] max-d:py-[60px]">
                                            <i className="ri-checkbox-circle-fill text-[60px] text-green-600"></i>
                                            <p className="text-2xl font-semibold">
                                                Thank you. <br /> Form submitted successfully!
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-center">
                                            <h4 className="font-semibold text-xl mb-3">
                                                Join Our Waitlist
                                            </h4>
                                            <form
                                                className="flex flex-col space-y-2.5 w-[75%] max-lg:w-full"
                                                onSubmit={handleSubmit(subscribe)}
                                            >
                                                <input
                                                    type="text"
                                                    name="FNAME"
                                                    value={formData.FNAME}
                                                    onChange={handleChange}
                                                    placeholder="First Name"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    name="LNAME"
                                                    value={formData.LNAME}
                                                    onChange={handleChange}
                                                    placeholder="Last Name"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    name="COMPANY"
                                                    value={formData.COMPANY}
                                                    onChange={handleChange}
                                                    placeholder="Business Name"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="email"
                                                    name="EMAIL"
                                                    value={formData.EMAIL}
                                                    onChange={handleChange}
                                                    placeholder="Email Address"
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    required
                                                />
                                                <input
                                                    type="tel"
                                                    name="PHONE"
                                                    value={formData.PHONE}
                                                    onChange={handleChange}
                                                    className="h-[50px] w-full py-0 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    placeholder="Phone Number (Optional)"
                                                />
                                                <textarea
                                                    name="MESSAGE"
                                                    value={formData.MESSAGE}
                                                    onChange={handleChange}
                                                    className="resize-none w-full py-1.5 px-3 border-[2px] border-black rounded-[10px] box-border bg-white"
                                                    rows={3}
                                                    placeholder="Message (Optional)"
                                                />
                                                <button
                                                    type="submit"
                                                    className="bg-black py-3 rounded-md text-white"
                                                >
                                                    {status === "sending"
                                                        ? "Joining..."
                                                        : "Join now"}
                                                </button>
                                            </form>

                                            {status === "error" && (
                                                <div style={{ color: "red" }}>{message}</div>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <p className="my-5 font-semibold text-white">
                        {" "}
                        &copy; {getCurrentYear()} NO-Q. All rights reserved.{" "}
                    </p>
                </div>
            </div>
        </div>
    );
};
export default LandingPage;
