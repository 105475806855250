import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import StoreDetail from "./pages/StoreDetail";
import ManageProducts from "./pages/Dashboard/ManageProducts";
import Signup from "./pages/Signup";
import ManageOrders from "./pages/Dashboard/ManageOrders";
import VendorPayment from "./pages/Dashboard/vendorPayment";
import EditStore from "./pages/EditStore";
import ResetPassword from "./pages/ResetPassword";
import LandingPage from "./pages/LandingPage";
import Docs from "./pages/Docs";

export const routes = [
    // {
    //     path: "/",
    //     element: <Login />,
    //     isPrivate: false,
    // },
    {
        path: "/",
        element: <LandingPage />,
        isPrivate: false,
    },
    // {
    //     path: "/login",
    //     element: <Login />,
    //     isPrivate: false,
    // },
    // {
    //     path: "/register",
    //     element: <Signup />,
    //     isPrivate: false,
    // },

    {
        path: "/LandingPage",
        element: <LandingPage />,
        isPrivate: false,
    },

    {
        path: "/Docs",
        element: <Docs />,
        isPrivate: false,
    },

    // {
    //     path: "/reset-password/:token",
    //     element: <ResetPassword />,
    //     isPrivate: false,
    // },
    {
        path: "/dashboard",
        element: <Dashboard />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:id",
        element: <StoreDetail />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/products",
        element: <ManageProducts />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/edit",
        element: <EditStore />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/orders",
        element: <ManageOrders />,
        isPrivate: true,
    },
    {
        path: "/dashboard/store/:storeId/payment",
        element: <VendorPayment />,
        isPrivate: true,
    },
    {
        path: "*",
        element: (
            <div className="h-screen w-screen flex flex-col justify-center items-center">
                <p className="text-[40px] font-semibold">404</p>
                <p className="text-[30px] font-semibold">Page not found</p>
            </div>
        ),
        isPrivate: false,
    },
];
